
.formContainer2{
    flex-direction: column;
}
.formContainer2 label {
    padding-left: 10px;
}
.formContainer2 input{
    width: 120%;
    border-radius: 0;
    line-height: normal;
    height: 32px;
    margin-bottom: 0px;
    padding: .1875rem .5rem;
    background-color: #45454a;
    border: 1px solid #606064;
    color: hsla(0,0%,100%,.8);
    font-family: Open Sans,sans-serif;
    transition-duration: .15s;
    transition-property: border,background-color,color,box-shadow;
    transition-timing-function: ease-in;
  }
  
  .formContainer2 .PhoneInput{
    width: 90%;
    border-radius: 0;
    line-height: normal;
    height: 32px;
    margin-bottom: 20px;
    padding: 0;
    background-color: #45454a;
    border: 1px solid #606064;
    color: hsla(0,0%,100%,.8);
    font-family: Open Sans,sans-serif;
    transition-duration: .15s;
    transition-property: border,background-color,color,box-shadow;
    transition-timing-function: ease-in;
  }
  
  .formContainer2 input:focus, .formContainer2 input:hover, .selectformContainer2:hover, .selectformContainer2:focus{
    border-color: #17a0fb;
    outline: none;
  }
  
  .formContainer2 label, .col-form-label, .selectformContainer2>option{
    color:#929295 !important
  }
  
  .selectformContainer2{
    width: 90%;
    border-radius: 0;
    line-height: normal;
    height: 32px;
    margin-bottom: 20px;
    padding: 0;
    background-color: #45454a;
    border: 1px solid #606064;
    color: hsla(0,0%,100%,.8);
    font-family: Open Sans,sans-serif;
    transition-duration: .15s;
    transition-property: border,background-color,color,box-shadow;
    transition-timing-function: ease-in;
    cursor: pointer;
  }
  

.formContainer2.smallform input{
    width: 90% !important;
}

.formContainer2.smallform label{
    padding-left: 0;
}

.tabsContainer>li>a.active{
  background-color: #26262b !important;
  margin-bottom: 10px;
}